import { useState } from 'react'
import Link from '@/components/ui/Link'
import { cn } from '@/lib/utils'

// Тестовые данные
// const MOCK_CATEGORIES = [
//   { id: '1', slug: 'facial-surgery', title: 'Facial Surgery' },
//   { id: '2', slug: 'nose-surgery', title: 'Nose Surgery' },
//   { id: '3', slug: 'eye-surgery', title: 'Eye Surgery' },
//   { id: '4', slug: 'neck-surgery', title: 'Neck Surgery' }
// ]

// const MOCK_ARCHIVES = [
//   { date: '2024-02-01', count: 5 },
//   { date: '2024-01-01', count: 3 },
//   { date: '2023-12-01', count: 4 },
//   { date: '2023-11-01', count: 2 }
// ]

export default function BlogTabs({data}: {data: {categories: any[], archives: any[]}}) {
  const [activeTab, setActiveTab] = useState<'categories' | 'archives'>('categories')

  const formatArchiveDate = (dateStr: string) => {
    const date = new Date(dateStr)
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
    })
  }

  return (
		<div className='mb-12'>
			{/* Tabs Header */}
			<div className='container mx-auto max-w-[1400px] px-4 mb-8'>
				<div className='flex gap-8 justify-center font-freightBig text-[1.2rem] md:text-[1.6rem] text-[#332f2c] mb-4 text-center font-bold'>
					<button
						onClick={() => setActiveTab('categories')}
						className={cn(
							'py-2 px-4 transition-colors relative',
							activeTab === 'categories'
								? 'text-primary after:content-[""] after:absolute after:bottom-0 after:left-[35%] after:w-[30%] after:h-[2px] after:bg-primary'
								: 'text-neutral-600 hover:text-primary'
						)}
					>
						Categories
					</button>
					<button
						onClick={() => setActiveTab('archives')}
						className={cn(
							'py-2 px-4 transition-colors relative',
							activeTab === 'archives'
								? 'text-primary after:content-[""] after:absolute after:bottom-0 after:left-[35%] after:w-[30%] after:h-[2px] after:bg-primary'
								: 'text-neutral-600 hover:text-primary'
						)}
					>
						Archives
					</button>
				</div>
			</div>
			
			{/* Tabs Content */}
			<div className='container mx-auto max-w-[1400px] px-4 post_tabs_content'>
				{activeTab === 'categories' ? (
					<div className='flex flex-wrap justify-center gap-3'>
						{data.categories.map(category => (
							<Link
								key={category.id}
								href={`/plastic-surgery-blog/category/${category.slug}`}
								className='inline-flex items-center justify-center px-[35px] py-[15px] text-[12px] font-plusJakarta font-bold uppercase tracking-[0.1em] text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black'
							>
								{category.title}
							</Link>
						))}
					</div>
				) : (
					<div className='flex flex-wrap justify-center gap-3'>
						{data.archives.map(archive => (
							<Link
								key={archive.date}
								href={`/plastic-surgery-blog/archive/${archive.date.slice(
									0,
									7
								)}`}
								className='inline-flex items-center justify-center px-[35px] py-[15px] text-[12px] font-plusJakarta font-bold uppercase tracking-[0.1em] text-[#332f2c] border border-[#b2b2b2] rounded-full transition-all duration-300 hover:bg-[#645e5e] hover:text-white hover:border-black'
							>
								{formatArchiveDate(archive.date)}
							</Link>
						))}
					</div>
				)}
			</div>
		</div>
	)
} 