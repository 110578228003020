import Image from "next/image";
import Link from "@/components/ui/Link";
import Button from "../../ui/button";
import { getReadableImageUrl } from "@/lib/directus";
import { sanitizeHTML } from "@/lib/utils";

interface ColumnProps {
  data: ColumnData;
}

interface ColumnData {
  text: string;
  links: {
    collection: string;
    item: {
      id: number;
      href: string;
      label: string;
      site_content_type: string;
      title: string;
    };
  }[];
  sections: {
    id: number;
    item: {
      collection: {
        collection: string;
        id: number;
        item: {
          alt: string;
          href: string;
          label: string;
          image_file: {
            image: {
              id: string;
              filename_download: string;
            };
          };
        };
      }[];
    };
  }[];
}

export default function Column({ data }: ColumnProps) {
  return (
    <div className="space-y-8 bg-[#ededed] p-[10px] rounded-xl w-full">
      {data.sections.map((item) => (
        <div key={item.id} className="space-y-4">
          {item.item.collection[0].collection === "images" && (
            <div
              key={`${item.item.collection[0].collection}-${item.item.collection[0].id}`}
            >
              <Link href={item.item.collection[1].item.href} className="block">
                <div className="relative aspect-[3/2]">
                  <Image
                    src={getReadableImageUrl(
                      item.item.collection[0].item.image_file.image.id,
                      item.item.collection[0].item.image_file.image
                        .filename_disk
                    )}
                    alt={item.item.collection[0].item.alt}
                    fill
                    className="object-cover rounded-xl"
                    quality={95}
                    loading={"lazy"}
                  />
                </div>
              </Link>
              <h3 className="font-freightBig text-2xl text-center">
                <Link
                  href={item.item.collection[1].item.href}
                  className="hover:text-primary"
                >
                  {item.item.collection[1].item.label} &gt;&gt;
                </Link>
              </h3>
            </div>
          )}
        </div>
      ))}
      <div className="space-y-4">
        <div className="h-px bg-white" />
        {data.links.map(
          (link) =>
            link.collection === "site_content" &&
            link.item.site_content_type === "title" && (
              <h2
                key={link.item.id}
                className="font-freightBig text-2xl text-center"
              >
                {link.item.title}
              </h2>
            )
        )}
        <div className="space-y-1 flex justify-center items-center flex-wrap gap-1">
          {data.links.map(
            (link) =>
              link.collection === "menu_items" && (
                <Button asChild key={link.item.id}>
                  <Link
                    href={link.item.href}
                    className="block text-center py-2 px-4 border border-[#b2b2b2] rounded hover:bg-[#645e5e] hover:text-white transition-colors"
                  >
                    {link.item.label}
                  </Link>
                </Button>
              )
          )}
        </div>
        <div className="h-px bg-white" />
        <div
          className="text-center"
          dangerouslySetInnerHTML={sanitizeHTML(data.text)}
        />
      </div>
    </div>
  );
}
