import Link from "@/components/ui/Link";
import Column from "./Column";
import { getBlogCategoryUrl } from "@/lib/blog";
import { sanitizeHTML } from "@/lib/utils";

export interface BlogPostSectionData {
  title: string;
  published_at: string;
  content: string;
  categories: {
    blog_categories_id: {
      title: string;
      slug: string;
    };
  }[];
  left_column_data: any;
}

interface BlogPostSectionProps {
  data: BlogPostSectionData;
}

export default function BlogPostSection({ data }: BlogPostSectionProps) {
  return (
    <section className="relative py-[50px] px-4">
      <div className="container mx-auto max-w-[1400px]">
        <div className="grid grid-cols-1 lg:grid-cols-[2fr_1fr] gap-14">
          <div className="w-full">
            <div className="space-y-4">
              <div className="flex flex-col space-y-2 mb-14">
                <h1
                  className="font-freightBig text-[32px] md:text-[40px] leading-tight"
                  dangerouslySetInnerHTML={sanitizeHTML(data.title)}
                ></h1>
                <div className="flex items-center space-x-2 text-sm text-gray-600">
                  <span>
                    {new Date(data.published_at).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    })}
                  </span>
                  <span>|</span>
                  {data.categories.map((category, index) => (
                    <>
                      <Link
                        key={category.blog_categories_id.slug}
                        href={getBlogCategoryUrl(
                          category.blog_categories_id.slug
                        )}
                      >
                        {category.blog_categories_id.title}
                      </Link>
                      {index < data.categories.length - 1 && <>,</>}
                    </>
                  ))}
                </div>
              </div>
              <div
                className="post-content prose max-w-none prose-img:mx-auto prose-p:pb-4 prose-p:font-sans prose-p:m-0 prose-img:m-0 prose-h1:font-freightBig prose-h2:font-freightBig prose-h3:font-freightBig prose-h4:font-freightBig prose-h5:font-freightBig prose-h6:font-freightBig"
                dangerouslySetInnerHTML={sanitizeHTML(data.content)}
              ></div>
            </div>
          </div>
          <div className="w-full">
            <Column data={data.left_column_data} />
          </div>
        </div>
      </div>
    </section>
  );
}
